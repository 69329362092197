import { graphql } from 'gatsby'
import React from 'react'

import { IndexQueryQuery } from '../../types/graphql-types'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'
import Slider from '../components/slider/slider'
import Appointment from '../components/appointment'
import About from '../components/about'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const Index: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta

  return (
    <Layout location={location}>
      <Meta site={meta} />
      <Slider location={location} />
      <div className="divider-90 d-none d-xl-block"></div>
      <div className="divider-20 d-xl-none"></div>
      <About />
      <div className="divider-90 d-none d-xl-block"></div>
      <div className="divider-20 d-xl-none"></div>
      <Appointment />
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query IndexQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
