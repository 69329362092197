import React from 'react'

function initCoverImage() {
  $('.bg_teaser, .cover-image').each(function () {
    const $element = $(this)
    let $image = $element.find('img').first()
    if (!$image.length) {
      $image = $element.parent().find('img').first()
    }
    if (!$image.length) {
      return
    }
    const imagePath = $image.attr('src')
    $element.css('background-image', 'url(' + imagePath + ')')
    const $imageParent = $image.parent()
    //if image inside link - adding this link, removing gallery to preserve duplicating gallery items
    if ($imageParent.is('a')) {
      $element.prepend($image.parent().clone().html(''))
      $imageParent.attr('data-gal', '')
    }
  })
}

function initSliderJquery() {
  if ($().flexslider) {
    const $introSlider = $('.page_slider .flexslider')
    $introSlider.each(function () {
      const $currentSlider = $(this)
      const data = $currentSlider.data()
      const nav = data.nav !== 'undefined' ? data.nav : true
      const dots = data.dots !== 'undefined' ? data.dots : true
      const speed = data.speed !== 'undefined' ? data.speed : 7000

      $currentSlider.flexslider({
        animation: 'fade',
        pauseOnHover: true,
        useCSS: true,
        controlNav: dots,
        directionNav: nav,
        prevText: '',
        nextText: '',
        smoothHeight: false,
        slideshowSpeed: speed,
        animationSpeed: 600,
        start: function (slider: any) {
          slider.find('.intro_layers').children().css({ visibility: 'hidden' })
          slider
            .find('.flex-active-slide .intro_layers')
            .children()
            .each(function (index: any) {
              const self = $(this)
              const animationClass = !self.data('animation')
                ? 'fadeInRight'
                : self.data('animation')
              setTimeout(function () {
                self.addClass('animated ' + animationClass)
              }, index * 250)
            })
          //////////////////////////////////////////
          const a = $('li:has(.flex-active)').index('.flex-control-nav li') + 1
          let prev = a - 1
          const next = a + 1
          if (prev == 0) {
            prev = $('.slides li').length
          }
          $('.flex-nav-prev a').text(prev + ' / ' + $('.slides li').length)

          $('.flex-nav-next a').text(next + ' / ' + $('.slides li').length)
        },
        after: function (slider: any) {
          slider
            .find('.flex-active-slide .intro_layers')
            .children()
            .each(function (index: any) {
              const self = $(this)
              const animationClass = !self.data('animation')
                ? 'fadeInRight'
                : self.data('animation')
              setTimeout(function () {
                self.addClass('animated ' + animationClass)
              }, index * 250)
            })
          //////////////////////////////////
          const a = $('li:has(.flex-active)').index('.flex-control-nav li') + 1
          let prev = a - 1
          let next = a + 1
          if (next == $('.slides li').length + 1) {
            next = 1
          }
          if (prev == 0) {
            prev = $('.slides li').length
          }
          $('.flex-nav-prev a').text(prev + ' / ' + $('.slides li').length)
          $('.flex-nav-next a').text(next + ' / ' + $('.slides li').length)
        },
        end: function (slider: any) {
          slider
            .find('.intro_layers')
            .children()
            .each(function () {
              const self = $(this)
              const animationClass = !self.data('animation')
                ? 'fadeInRight'
                : self.data('animation')
              self
                .removeClass('animated ' + animationClass)
                .css({ visibility: 'hidden' })
              // $(this).attr('class', '');
            })
        },
      })
      //wrapping nav with container - uncomment if need
      // .find('.flex-control-nav')
      // .wrap('<div class="container nav-container"/>')
    }) //.page_slider flex slider

    $('.flexslider').each(function () {
      const $currentSlider = $(this)
      //exit if intro slider already activated
      if ($currentSlider.find('.flex-active-slide').length) {
        return
      }
      $currentSlider.flexslider({
        animation: 'fade',
        useCSS: true,
        controlNav: true,
        directionNav: false,
        prevText: '',
        nextText: '',
        smoothHeight: true,
        slideshowSpeed: 5000,
        animationSpeed: 800,
      })
    })
  }
}

class Slider extends React.Component<Props, {}> {
  componentDidMount() {
    $(document).ready(initCoverImage)
    $(document).ready(initSliderJquery)
  }

  render() {
    return (
      <section className="page_slider">
        <div className="flexslider nav-true">
          <ul className="slides">
            <li className="ls cover-image">
              <img src="/images/slide01.jpg" alt="" />
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="intro_layers_wrapper">
                      <div className="intro_layers">
                        <div
                          className="intro_layer"
                          data-animation="fadeInLeft"
                        >
                          <h2 className="intro_featured_word">
                            Enhance
                            <br />
                            your beauty
                          </h2>
                        </div>
                        <div
                          className="intro_layer"
                          data-animation="fadeInLeft"
                        >
                          <picture>
                            <source
                              srcSet="/images/slide05.webp"
                              type="image/webp"
                            />
                            <source
                              srcSet="/images/slide05.png"
                              type="image/png"
                            />
                            <img
                              src="/images/slide05.png"
                              alt="Enhance your beauty"
                              className="intro_image"
                            />
                          </picture>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li className="ls cover-image second-cover">
              <picture>
                <source srcSet="/images/slide.webp" type="image/webp" />
                <source srcSet="/images/slide.jpg" type="image/jpeg" />
                <img
                  src="/images/slide.webp"
                  alt="Discount aesthetic treatment"
                />
              </picture>
            </li>
          </ul>
        </div>
        <div className="prev-slide"></div>
        <div className="next-slide"></div>
      </section>
    )
  }
}

export default Slider
