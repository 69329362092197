import React from 'react'

const About: React.FC = () => {
  return (
    <section className="ls hello s-pt-50 s-pt-lg-100 s-pt-xl-150 c-mb-30 c-gutter-60">
      <div className="container" id="about">
        <div className="row">
          <div className="col-12 col-md-5 d-block">
            <picture>
              <source
                srcSet="/images/main_page_welcome.webp"
                type="image/webp"
              />
              <source srcSet="/images/main_page_welcome.jpg" type="image/jpg" />
              <img src="/images/main_page_welcome.jpg" alt="Adrianna Borek" />
            </picture>
          </div>
          <div className="col-md-7 col-12">
            <div className="divider-60 d-none d-xl-block"></div>
            <div className="title-section text-center text-md-left">
              <span className="sub-title absolute-subtitle">welcome</span>
              <h1 className="special-heading">
                <span>
                  <span>Ada</span> Aesthetics
                  <br />
                </span>
                Cheshunt
              </h1>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <p className="text-center text-md-left">
                  Our mission is to provide results-driven treatments with
                  natural-looking results: your skin, only better. We make sure
                  that all our clients look and feel their best before leaving
                  our salon in Cheshunt, Waltham Cross.
                </p>
                <p className="text-center text-md-left">
                  We specialise in a wide range of advanced facial aesthetics
                  and beauty treatments, including anti wrinkle, cheek fillers,
                  and lip fillers to enhance your features and boost your
                  confidence.
                </p>
              </div>
              <div className="col-lg-12">
                <p className="text-center text-md-center">
                  Ada Aesthetics: Facial aesthetics you can trust. <br />
                </p>
                <p className="text-center text-md-center">
                  <i>
                    “Let me use my years of clinical experience to rejuvenate
                    your skin.”
                  </i>
                </p>
              </div>
            </div>
            <div className="divider-40 d-none d-xl-block"></div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
